<template>

  <div>

    <b-card class="mb-0">
      <b-row>
        <b-col cols="12">
          <TableListProducts />
        </b-col>
      </b-row>
    </b-card>


  </div>
</template>
  
<script>

import TableListProducts from './TableListProducts.vue'
import ProductCategory from './ProductCategory.vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab, BCardImg, BCardFooter,
} from 'bootstrap-vue'


export default {
  components: {
    TableListProducts,
    BTabs,
    BTab,
    ProductCategory,
    BCard,
    BCardImg,
    BCardFooter,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },


}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
  
<template>

    <b-card>

        <div class="py-1 d-flex justify-content-end">
            <b-button variant="primary" activev-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-addcategory>
                <i class="feather icon-plus"></i>
                Add New Category
            </b-button>
        </div>
        <b-row class="d-flex justify-content-end">
            <b-col md="6" class="my-1">
            <b-form-group  label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>
        </b-row>
      
        <b-table responsive="lg" :items="items" />
        <div class="d-flex justify-content-center">
            <b-pagination
    v-model="currentPage"
    hide-goto-end-buttons
    :total-rows="rows"
  />
        </div>
      
        <b-modal id="modal-addcategory" cancel-variant="outline-secondary" ok-title="Add" cancel-title="Close" centered
            title="Add Category">
            <b-form>
                <b-form-group>
                    <label for="Category">Category ID</label>
                    <b-form-input id="text" type="text" placeholder="3192DG" readonly />
                </b-form-group>
                <b-form-group>
                    <label for="Name">Category Name</label>
                    <b-form-input type="text" placeholder="Category 6" />
                </b-form-group>
            </b-form>
        </b-modal>
    </b-card>

</template>
  
<script>
import { BTable, BCard, BContainer, BButton, BForm, BFormGroup, BFormInput, BModal, VBModal, BRow, BCol, BPagination  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BTable,
        BContainer,
        BButton,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BCard,
        BModal,
        BPagination,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    data() {
        return {
            currentPage: 1,
      perPage: 1,
      rows: 100,
            items: [
                {
                    CategoryId: 1, CategoryName: 'Category 1', Status: 'Available',
                },
                {
                    CategoryId: 2, CategoryName: 'Category 2', Status: 'Low Stock',
                },
                {
                    CategoryId: 3, CategoryName: 'Category 3', Status: 'Out of Stock',
                },
                {
                    CategoryId: 4, CategoryName: 'Category 4', Status: 'Available',
                },
                {
                    CategoryId: 5, CategoryName: 'Category 5', Status: 'Out of Stock',
                },
            ],
        }
    },
}
</script>
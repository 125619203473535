<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" to="/product/add">
          <feather-icon icon="PlusIcon" class="mr-50" />
          Add Product
        </b-button>
      </div>
    </b-col>

    <b-col cols="12">
      <b-row>
        <b-col cols="3">
          <b-form-group label="Product Name" label-for="h-Order-name">
            <b-form-select v-model="selectedName" :options="productName" @change="orderFilterChanged()">
              <option value="">All</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Product Code" label-for="h-Order-code">
            <b-form-select v-model="selectedCode" :options="productCode" @change="orderFilterChanged()">
              <option value="">All</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col col="3" class="d-flex align-items-center">
          <b-button @click="clearFilters">
            Clear
          </b-button>
        </b-col>

      </b-row>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>

    <b-col md="10" class="my-1">
      <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to filter by Description" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="productListing"
        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(balance)="data">
          <b-button :to="getProductStock(data.item.id)">
            <feather-icon icon="EyeIcon" class="mr-50" />
            <span>Stock Balance</span>
          </b-button>
        </template>
        <template #cell(inHouseManufactured)="data">
          <b-badge v-if="data.item.inHouseManufactured" variant="primary">
            Yes
          </b-badge>
          <b-badge v-if="!data.item.inHouseManufactured" variant="info">
            No
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item v-b-modal.modal-edit :to="getProductId(data.item.id)">
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span>View</span>
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.modal-edit :to="getProductEdit(data.item.id)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeProduct(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
        class="my-0" />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      productName: [],
      productCode: [],
      selectedName: null,
      selectedCode: null,
      productData: [],
      productListing: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "name",
        "code",
        "description",
        { key: "balance", label: "View Balance" },
        { key: "inHouseManufactured", label: "inHouseManufactured", sortable: true },
        "actions",
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    Product() {
      return this.productList.filter((item) => item.type !== 4);
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("productModule", {
      productList: "product",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.productList.length;
  },
  methods: {
    clearFilters() {
      this.selectedName = '';
      this.selectedCode = '';

      this.productListing = [...this.productList];
      this.totalRows = this.leads.length;
      this.currentPage = 1;
    },
    orderFilterChanged() {
      this.productListing = this.productList;
      if (this.selectedName) {
        this.productListing = this.productListing.filter((obj) => {
          return obj.id === this.selectedName;
        });
      }
      if (this.selectedCode) {
        this.productListing = this.productListing.filter((obj) => {
          return obj.code === this.selectedCode;
        });
      }

      this.totalRows = this.productListing.length;
      this.currentPage = 1;
    },
    getProductData(data) {
      this.productData = data;
    },
    removeProduct(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeProductAction(id).then(() => {
            this.$swal({
              title: "Deleted!",
              text: "This product has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }).catch((error) => {
            this.$swal({
              title: "Error!",
              text: " Failed! " + error.response.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Your product is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ...mapActions("productModule", ["getProductListAction", "removeProductAction"]),
    getProductId(item) {
      return "/product/view/" + item;
    },
    getProductEdit(item) {
      return "/product/edit/" + item;
    },
    getProductStock(item) {
      return "/product/stockTransaction/" + item;
    },
  },
  async mounted() {
    await this.getProductListAction()
      .then(() => {
        this.productListing = this.productList.filter((product) => product.type !== 4);
        this.totalRows = this.productListing.length;
        let addedCodes = {};
        this.productListing.map((v, i) => {
          if (v && v.id !== null && v.name !== null) {
            this.productName.push({ value: v.id, text: `${v.name}` });
          }
          if (v && v.id !== null && v.code !== null) {
            if (!addedCodes[v.code]) {
              this.productCode.push({ value: v.code, text: `${v.code}` });
              addedCodes[v.code] = true;
            }
          }
        });
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
